import store from '@/store'

export default {
	auth: {
		bind:function(el,binding){
            if(typeof binding.value == 'string'){
                let auth = store.state.page_auth[binding.value]
                if(!auth && auth !== undefined){
                    el.style.display = 'none'
                }
            }else{
                // 当传入的value是个对象时，判断type的类型 type== 'reverse'时，表示取反, type == 'forward'时取正
                let auth = binding.value.value.split(',')
                if(binding.value.type == 'reverse'){ 
                    if(auth.every(key => store.state.page_auth[key] === true)){
                        el.style.display = 'none'
                    }
                }else if(binding.value.type == 'forward'){
                    if(!auth.every(key => store.state.page_auth[key] === true)){
                        el.style.display = 'none'
                    }
                }
            }
			
		},
		update:function(el,binding){
            if(typeof binding.value == 'string'){
                let auth = store.state.page_auth[binding.value]
                if(!auth && auth !== undefined){
                    el.style.display = 'none'
                }
            }else{
                // 当传入的value是个对象时，判断type的类型 type== 'reverse'时，表示取反, type == 'forward'时取正
                let auth = binding.value.value.split(',')
                if(binding.value.type == 'reverse'){ 
                    if(auth.every(key => store.state.page_auth[key] === true)){
                        el.style.display = 'none'
                    }
                }else if(binding.value.type == 'forward'){
                    if(!auth.every(key => store.state.page_auth[key] === true)){
                        el.style.display = 'none'
                    }
                }
            }
		}
	}
}