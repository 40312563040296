import req from './req'
import {getGoodsTitle,getGoodsTitleV2} from '../api/goods'
import { message } from 'ant-design-vue'
import config from './config'
import moment from 'moment'
let is_click = true;

export default {
	/**
	 * 获取url 参数
	 */
	get_query() {
		let query = {};
		let search = location.search
		if (search.length > 2) {
			search = search.split('?')[1].split('&')
			for (let i = 0; i < search.length; i++) {
				let arr = search[i].split('=');
				if (arr.length == 2) {
					query[arr[0]] = arr[1]
				}
			}
		}
		return query
	},
	
	set_local(key, value) {
		localStorage.setItem(key, value);
	},
	get_local(key) {
		return localStorage.getItem(key);
	},
	remove_local(key) {
		localStorage.removeItem(key);
	},
 /**
     * 导出
     */
   handleExport(searchParams, apiUrl){
     searchParams.token = localStorage.getItem('token')
     const params = Object.entries(searchParams).map(item => {
       return `${item[0]}=${item[1]}`
     }).join('&')
     const a = document.createElement('a')
     a.href = `${config.host}${apiUrl}?${params}`
     a.click()
   },
	get_time(time, type) {
		if (time) {
			return time.format(type || 'HH:mm')
		} else {
			return ''
		}
	},

	get_date(date, type) {
		if (date) {
			return moment(date).format(type || 'YYYY-MM-DD')
		} else {
			return ''
		}
	},

	to_url(data = {}){
		return Object.keys(data).map(function (key) {
			// body...
			return key + "=" + (data[key] !== undefined ? data[key] : '');
		}).join("&");
	},

	// 获取form_data.list中,关联的两个表单数据 
	get_form_relation_name(target, list, name) {
		let data = {};
		for (let i = 0; i < list.length; i++) {
			let item = list[i];
			if (item.name == target.name && item.relation_name) {
				if (item.name == name) {
					for (let j = 0; j < list.length; j++) {
						if (item.relation_name == list[j].name) {
							data.relation_name = list[j];
							break;
						}
					}
					data.item = list[i];
					break;
				}
			}
		}
		return data;
	},

	get_list({
		_this,
		url,
		data,
		list_name = 'list'
	}) {
		return new Promise((resolve, reject) => {
			_this.loading = true;
			if(_this.is_page){
				data.list_rows = _this.pagination.pageSize
			}
			req({
				url,
				data: {
					page: _this.page,
					...data
				}
			}).then(res => {
				_this.loading = false;
				let data = {
					res,
				};
				let list = res.data.list.data;
				if(_this.is_page){
					let pagination = {..._this.pagination}
					pagination.total = res.data.list.total;
					pagination.pageSize = res.data.list.per_page;
					pagination.current = res.data.list.current_page;
					_this.pagination = pagination;
				}
				
				if (Array.isArray(list)) {
					_this[list_name] = list;

				}
				resolve(data)
			}).catch(res => {
				_this.loading = false;
				
				reject({
					code: -1,
					res,
				})
			})
		})
	},

	// 获取部门
	get_department(is_top = false, uid = 0) {
		let data = {}
		if(is_top && typeof is_top == 'Boolean'){
			data.top = 1;
		}
		if(uid > 0){
			data.uid = uid;
		}
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getDepartmentTreeList',
				data: data
			}).then(res => {
				let data = res.data.list
				this.get_depart_list(data)
				resolve(data)
			})
		})
	},
	// 获取岗位树形
	get_role_tree(is_top = false, uid = 0) {
		let data = {}
		if(is_top){
			data.top = 1;
		}
		if(uid > 0){
			data.uid = uid;
		}
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getPostTreeList',
				data: data
			}).then(res => {
				let data = res.data.list
				this.get_depart_list(data)
				// if(is_top) {
				// 	data = [{title:"顶级岗位",value:999,key:999,children:data,id:999,name:"顶级岗位",pid:0}]
				// }
				resolve(data)
			})
		})
	},
	get_depart_list(item, children = 'sub',key = 'id', title = 'name') {

		for (let i = 0; i < item.length; i++) {
			item[i].title = item[i][title]
			item[i].value = item[i][key]
			item[i].key = item[i][key]
			if (Array.isArray(item[i][children]) && item[i][children].length) {
				item[i].children = item[i][children] || []
				this.get_depart_list(item[i][children],children,key,title);
			}
		}
		return item
	},
	// 获取岗位
	get_role() {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getRoleTreeList',
			}).then(res => {
				let data=res.data.list
				data=data.map(item=>{
					item.key=item.id
					item.value=item.name
					return item
				})
				resolve(data)

			})

		})

	},

	//获取客户城乡类型 manage/crm.customer/getRegionType
	get_RegionType() {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.customer/getRegionType',
			}).then(res => {
				let data = res.data.list
				data=data.map(item=>{
					item.key=item.key,
					item.value=item.value
					return item
				})
				resolve(data)
			})
		})
	},
	//获取类型
	get_group(data) {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getCustomerGroupList',
				data
			}).then(res => {
				let data = res.data.list
				this.get_group_list(data)
				resolve(data)
			})
		})
	},
	//获取品项
	get_goods() {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.goods/getGoods',
			}).then(res => {
				let data = []
				res.data.list.forEach(item=>{
					var g = {key:item.id,value:item.goods_title}
					data.push(g)
				})
				resolve(data)
			})
		})
	},
	get_group_list(item) {

		for (let i = 0; i < item.length; i++) {
			item[i].label = item[i].name
			item[i].value = item[i].id
			if (Array.isArray(item[i].childlist) && item[i].childlist.length) {
				item[i].children = item[i].childlist || []
				this.get_group_list(item[i].childlist);
			}
		}
		return item
	},


	//获取客户等级
	get_level() {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getCustomerLevelList',
			}).then(res => {
				let data = res.data.list
				data=data.map(item=>{
					item.key=item.id,
					item.value=item.name
					return item
				})
				resolve(data)
			})
		})
	},

	// 获取用户归属部门
	get_user_department() {
		return new Promise((resolve, reject) => {
			req({
				url: '/manage/crm.common/getUserToDepartment',
			}).then(res => {
				
				let data = res.data.list
				this.get_user_department_list(data)
				resolve(data)
			})
		})
	},


	get_goods_title(data){
		return getGoodsTitle({...data});
	},
	get_goods_v2(data){
		return getGoodsTitleV2({...data});
	},
	get_user_department_list(item,typeClass = '') {
		for (let i = 0; i < item.length; i++) {
			if(item[i].type=="class"){
				item[i].title = item[i].name
			}else{
				item[i].title = item[i].username
			}
			
			if(typeClass == 'sysUserTree' && item[i].type != 'class'){
                item[i].key = item[i].type +'_'+ item[i].id +'_'+ item[i].role_id
            }else{
                item[i].key = item[i].type +'_'+ item[i].id
            }

			if (Array.isArray(item[i].list) && item[i].list.length) {
				item[i].children = item[i].list || []
				this.get_user_department_list(item[i].list,typeClass);
			}
		}
		return item
	},

	// 文件转链接
	files_to_string(files){
		let arr = files.map(item=>{
			return item.url ? item.url : item.response.url
		})
		return arr.join(',');
	},

	to_form_list(list,key,value){
		let new_list = [];
		list.forEach(item => {
			new_list.push({
				key:item[key],
				value:item[value]
			})
		});
		return new_list;
	},

	// 设置form表单中的设置
	set_form_list(list,name,key,value){
		return list.map(item => {
			if (item.name == name) {
				item[key] = value;
			}
			return item;
		});
	},


	import_upload(e){
		return new Promise((resolve, reject) => {
			req({
				mode:'file',
				url:'/manage/api.plugs/upload',
				data:{
					file:e.file,
				}
			}).then(res=>{
				e.onSuccess(res.data.upload_data);
				resolve(res);
			}).catch(e=>{
				e.onError();
				reject(e)
			})
		})
	},

	import_upload_change(e,url){
		
	},

	format (num) {
		var reg=/\d{1,3}(?=(\d{3})+$)/g; 
		return (num + '').replace(reg, '$&,');
	},
	/**
	 * 防抖
	 * @param {*} fun 回调函数
	 * @param {*} time 防抖时间
	 */
	 debounce(fun, time = 1000) {
		if (is_click) {
			is_click = false;
			fun && fun()
			setTimeout(() => {
				is_click = true;
			}, time)
		} else {
			message.error('请稍等');
		}
	},
	/**
	 * 判断一串字符是否全部都是数字
	 * @param {String} string
	 * @param {Boolean} integer 是否只判断整型
	 */
	isAllNumber(string,integer = false){
		if(integer){
			return /^\d+$/.test(string); // 整型
		}else{
			return /^[0-9]+\.?[0-9]*$/.test(string) // 小数
		}
	},
	/**
	 * 为TableList组件设置筛选项的值
	 * @param {Object} that 当前页面实例
	 * @param {Object} query 需要设置的键与值，如：{ use_status:1 }
	 */
	setSeoFormValue(that,query){
		/**
		 * 由于select || radio 类型的选择框大部分时候是Number类型
		 * 如果设置时是字符串则会导致UI层面的筛选项表单直接将key显示出来而并非value
		 * 因此我需要判断每个对象的值是否全部都是数字，如果是的话则将其转换为Number类型(通过路由传参过来的数字将会是字符串类型)
		 */
		for(let i in query){
			
			if(this.isAllNumber(query[i])&&i!='keyword') query[i] = Number(query[i])
		}
		
		that.$refs.list.$refs.form_seo.form.setFieldsValue(query);
		that.$refs.list.fixed_seo_data = query
	},

	/**
	 * 获取两个日期中所有的月份
	 * @param {*} start 
	 * @param {*} end 
	 * @returns 
	 */
	getMonthBetween(start,end){  
		var result = []
		let newStart = start
		let newEnd = end
		if(start.indexOf('-') > -1){
			newStart = start.split("-")
			newStart = newStart[0]+''+newStart[1]
		}
		if(end.indexOf('-') > -1){
			newEnd = end.split("-")
			newEnd = newEnd[0]+''+newEnd[1]
		}
		var y1=parseInt(newStart.substr(0,4))
		var m1=parseInt(newStart.substr(4,2))-1
		var y2=parseInt(newEnd.substr(0,4))
		var m2=parseInt(newEnd.substr(4,2))-1
		do{
			var d = new Date(y1,m1++,1)
			var y = d.getFullYear()
			var m = d.getMonth() + 1
			if(start.indexOf('-') > -1 && end.indexOf('-') > -1){
				var s = y +'-'+ (m < 10 ? "0" : "") + m
			}else{
				var s = y + (m < 10 ? "0" : "") + m
			}
			result.push(s)
		}while(d < new Date(y2,m2,1))
		return result
	 },
	getBeforeDate() {
		const date = new Date()
		let time = date.getTime()
		time -= 30 * 86400000
		const nDate = new Date(time)
		return moment(nDate.toLocaleDateString())
	},
}