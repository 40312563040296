import Vue from 'vue'
import method from './method';
import config from './config'
import axios from 'axios'
import { message,Modal } from 'ant-design-vue'
import Jsonp from 'jsonp'
import router from '../router/index'
import login_after from './login_after'
import store from '../store'

function error_handle(msg = '网络错误') {
	message.error(msg);
}


// 添加请求拦截器
// axios.interceptors.request.use(function (config) {
//     // 在发送请求之前做些什么
//     // message.loading('加载中',10)
//     return config;
// }, function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
// // });

// // 添加响应拦截器
// axios.interceptors.response.use(function (response) {
//     // 对响应数据做点什么
//     // message.destroy();
//     return response;
// }, function (error) {
//     // 对响应错误做点什么
//     error_handle();
//     return Promise.reject(error);
// });

//退出对话框的状态
var loginOutStatus = true

function req({
	url = '',
	mode = 'api',
	base_url = '',
	data = {},
	type = 'post',
	login = false,
	jsonp = false,
	info = false,
	loading = false,
	loading_msg = '加载中...',
}) {
	return new Promise((resolve, reject) => {

		if (process.env.NODE_ENV === 'development') {
			url = '/apis' + url;
		} else {
			url = config.host + url;
		}

		let token = method.get_local('token');

		if (!token && !login) {
			let page_query = store.state.page_query;
			if (!page_query.token) {
				router.push('/login')
				return reject()
			}
		}

		if (jsonp) {
			Jsonp(base_url ? base_url : url, {
				...data
			}, (e, data) => {
				if (e) {
					reject(e)
				} else {
					resolve(data)
				}
			})
			return;
		}

		let headers = {
			"content-type": "application/json",
		};

		if (!login) {
			headers.token = token;
		}

		let req_data = {
			...data
		};

		if (mode == 'file') {
			headers['content-type'] = 'multipart/form-data';
			req_data = new FormData();
			for (let key in data) {
				req_data.append(key, data[key]);
			}
		}
		let hide = null
		if (loading) {
			hide = message.loading(loading_msg, 0)
		}
		let axiosUrl = base_url ? base_url : url
		axios({
			method: type,
			url: axiosUrl,
			headers,
			data: req_data,
		}).then(res => {
			res = res.data
			if (res.code == 1) {
				if (info) {
					message.success(res.info);
				}
				resolve(res)
				if (!window.is_login) {
					window.is_login = true;
					login_after();
				}
			} else if (res.code == 0) {
				error_handle(res.info)
				reject(res)
			}else if (res.code == -5) {
				reject(res)
			} else if (res.code == -6) {
				if (axiosUrl.indexOf('manage/api.login/index') > -1) {
					reject(res)
				} else {
					if (loginOutStatus) {
						loginOutStatus = false
						Modal.warning({
							title: '温馨提示',
							content: '密码已失效，重新修改后登陆~',
							okText: '去修改',
							onOk() {
								loginOutStatus = true
								method.set_local('loselogin', -6);
								router.replace('/login')

							}
						});
					}
				}
			}  else if (res.code == -2) {
				if(loginOutStatus){
					loginOutStatus = false
					Modal.warning({
						title: '温馨提示',
						content: '账号已失效，重新登录~',
						okText: '去登录',
						onOk() {
							loginOutStatus = true
							window.location.href = window.location.origin + '/login'
						}
					});
				}
			}
		}).catch(e => {
			error_handle()
			reject(e)
		}).finally(() => {
			if (loading && hide) {
				setTimeout(hide, 0)
			}
		})
	})
}


export default req;